.UI-Switch {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.UI-Switch__name {
    margin-left: 8px;
    font-size: 14px;
    color: var(--color-white);
    font-weight: 400;
}

.UI-Switch__input {
    display: none;
}

.UI-Switch__slider {
    position: relative;
    width: 34px;
    height: 14px;
    background-color: #ccc;
    border-radius: 7px;
    transition: background-color 0.2s;
}

.UI-Switch__slider::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    top: -3px;
    left: -3px;
    transition: transform 0.2s;
}

.UI-Switch__slider--checked {
    background-color: var(--color-teal);
}

.UI-Switch__slider--checked::before {
    transform: translateX(20px);
}
