.F-Upload {
    position: relative;
    display: block;
    max-width: 600px;
    margin: 0 auto;
    padding: 25px;
    border: 1px solid var(--color-gray-20);
    border-radius: var(--radius-35);
}

.F-Upload__email-text {
    font-size: 12px;
    color: var(--color-gray);
}

.F-Upload__or {
    margin-bottom: 10px;
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    color: var(--color-gray);
}

.F-Upload__hr {
    margin: 20px 0;
    border-top: 1px dashed var(--color-gray-20);
}

.F-Upload__footer {
    margin-top: 35px;
}

.F-Upload__header-text-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.F-Upload__past-example {
    color: var(--color-teal);
    cursor: pointer;
}

.F-Upload__or {
color: var(--color-gray);
font-size: 25px;
font-weight: 500;
margin-bottom: 10px;
text-align: center;
}

.F-Upload__wrap-spin {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
}

.F-Upload__container-alert {
    margin-bottom: 25px;
}

.F-Result {
    max-width: 1000px;
    margin: 0 auto;
    padding: 25px;
    border: 1px solid var(--color-gray-20);
    border-radius: var(--radius-35);
}

.F-Result__number {
    padding: 20px 0;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

.F-Result__header {
    padding: 20px 0;
    font-size: 28px;
    font-weight: 500;
    text-align: center;
}

.F-Result__subheader{
    padding: 20px 0;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
}

.F-Result__3dmol {
    position: relative;
    height: 500px;
    border: 1px solid var(--color-gray-20);
    border-radius: var(--radius-35);
    overflow: hidden;
}

.F-Result__heatmap {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-bottom: 6px;
    border-radius: 12px;
    overflow: hidden;
}

.F-Result__legent {
    margin-bottom: 26px;
}

.F-Result__legent-line {
    height: 16px;
    border-radius: 12px;
    /*background: linear-gradient(90deg, #161c27 0%, #11d6bc 100%);*/
    background: linear-gradient(to right, rgba(220,220,220,0), rgba(220,220,220,1));
}

.F-Result__legent-flex {
    display: flex;
    justify-content: space-between;
}

.F-Result__wrap-download {
    display: flex;
    justify-content: center;
}

.F-Result__toggle {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    gap: 24px;
}

.F-Result__protein-sequence {
    margin-bottom: 32px;
    font-family: monospace;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.F-Result__heatmap_labels {
    display: flex;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 50px;
    transform: rotate(90deg);
}


.F-Result__heatmap_escape {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-bottom: 18px;
    overflow: hidden;
}

.F-Result__plain_text {
    padding: 20px 0;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
