.UI-Tooltip {
    position: relative;
    margin-left: 6px;
}

.UI-Tooltip i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    font-size: 12px;
    font-style: normal;
    border-radius: 100%;
    background-color: var(--color-gray-10);
    transition: .3s;
}

.UI-Tooltip i:hover {
    color: var(--color-teal);
}

.UI-Tooltip span {
    position: absolute;
    bottom: calc(100% + 5px);
    left: 0;
    display: none;
    width: max-content;
    max-width: 400px;
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 6px;
    border: 1px solid var(--color-gray-20);
    background-color: var(--color-gray-10);
}

.UI-Tooltip:hover span {
    display: block;
}

